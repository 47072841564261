import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Modal from 'react-modal';

import IconCross from '../assets/images/icons/other/cross.inline.svg';

Modal.setAppElement('#___gatsby');

const ErrorModalBlock = forwardRef((props, ref) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    openModal() {
      setIsOpen(true);
      document.querySelector('body').style.overflow = 'hidden';
    },
  }));

  function closeModal() {
    setIsOpen(false);
    document.querySelector('body').style.overflow = 'auto';
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      overlayClassName="modalOverlay"
      className="modalContent modalErrorContent"
    >
      <div className="modalContent__top">
        <button onClick={closeModal} type="button">
          <IconCross />
        </button>
      </div>

      <div className="step-1">
        <div className="step-1__top">
          <h2 className="title-section">Ooops :(</h2>
          <p className="paragraph">Something went wrong...</p>
        </div>
      </div>
    </Modal>
  );
});

export default ErrorModalBlock;

import React, { Component, useRef } from 'react';
import { Link } from 'gatsby';
import queryString from 'query-string';
import { Location } from '@reach/router';
import { useHistory } from "react-router-dom";

import SEO from '../components/SEO';
import HeadingSection from '../components/HeadingSection';
import SuccessModalBlock from '../components/SuccessModalBlock';
import ErrorModalBlock from '../components/ErrorModalBlock';

import imageIllustrationContactUs from '../assets/images/illustrations/Contact_us.png';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const GetInTouch = () => (
  <>
    <SEO title="Verify yourself" description="" />

    <div className="common-page get-in-touch">
      <HeadingSection
        h1="Verify yourself to start using Sprive"
        image={imageIllustrationContactUs}
      />
      <ContactForm />
    </div>
  </>
);

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.childSuccessModalBlockRef = React.createRef();
    this.errorModalBlockRef = React.createRef();
  }

  componentDidMount() {
    const params = queryString.parse(location.search);
    const link = params.link;
    if (link) {
      console.log(link);
      window.open(link);
      //if (typeof window !== `undefined`) window.location.replace(link) // highlight-line
    }
  }

  handleChange = (event) => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  handleSubmitForm = (event) => {
    try {
      event.preventDefault();

      console.log(location);
      const params = queryString.parse(location.search);
      const link = params.link;
      if (link) {
        console.log(link);
        window.location.href = link;
      }
      else {
        this.errorModalBlockRef.current.openModal();
      }

    }
    catch (error) {
      this.errorModalBlockRef.current.openModal();
      console.log(error);
    }
  };

  render() {
    const { name, email, message } = this.state;

    return (
      <>
        <section className="first-section">
          <div className="container">
            <div className="first-section_content">
              <h2>Click to verify yourself</h2>
              <form
                className="wrapper"
                method="POST"
                onSubmit={this.handleSubmitForm}
              >
                <input type="hidden" name="form-name" value="sprive-form" />
                <div hidden>
                  <label>
                    Don’t fill this out if you're human: <input name="bot-field" />
                  </label>
                </div>
                <button name="send-button" type="submit">
                  Open Sprive
                </button>
              </form>
            </div>
          </div>
        </section>
        <SuccessModalBlock ref={this.childSuccessModalBlockRef} />
        <ErrorModalBlock ref={this.errorModalBlockRef} />
      </>
    );
  }
}

export default GetInTouch;
